<template>
  <div class="login-con">
    <img width="100%" height="100%" src="videop.png" alt="" />
    <img class="bg" :src="require('@/assets/images/false.png')" />
    <div class="mian-con">
      <div class="">
        <img class="loginbg" :src="require('@/assets/images/loginbg.png')" />
        <div @click="handleSubmit" class="login-box">
          <img class="loginBox" :src="require('@/assets/images/loginw.png')" />
          <span v-if="!Loading" class="loading-t">立即登录</span>
          <div v-if="Loading" class="loading-con">
            <a-spin size="large" />
          </div>
        </div>

        <div class="logincard-con">
          <div class="input-con">
            <img :src="require('@/assets/images/zhangh.png')" />
            <h1 class="line"></h1>
            <input v-model="userName" placeholder="账号" type="text" />
          </div>
          <div class="input-con">
            <img :src="require('@/assets/images/mima.png')" />
            <h1 class="line"></h1>
            <input
              v-model="password"
              placeholder="密码"
              type="password"
              id="demo_input"
              name="password"
            />
            <a-icon
              v-if="!showpass"
              @click="hideShowPsw"
              type="eye-invisible"
              id="demo_img"
            />
            <a-icon
              id="demo_img"
              v-if="showpass"
              @click="hideShowPsw"
              type="eye"
            />
          </div>
          <div v-if="submitLoading" class="tips-con">
            <img :src="require('@/assets/images/tisp.png')" alt="" />
            <h1>{{ tipsInfo }}</h1>
          </div>
        </div>
      </div>
      <!-- <div class="login">
                
        <div class="logintext">Login</div>
                
        <div class="field">
                      <input type="text" v-model="userName" placeholder="" />
                      
          <div class="placeholder">Username</div>
                  
        </div>
                
        <div class="field">
                      <input
            type="password"
            v-model="password"
            placeholder=""
          />
                      
          <div class="placeholder">Password</div>
                  
        </div>
                
        <div @click="handleSubmit" class="loginbtn">Continue</div>
            
      </div> -->
    </div>
    <p class="box">
      @2022-2023 重庆市农产品质量大数据 All rights reserved.
      裕狱中ICP备2022013057号
    </p>
  </div>
</template>

<script>
import loginApi from "@/request/api/user.js";
import storage from "@/storage/index.js";
import MutationType from "@/store/mutation-type.js";
import { spin } from "ant-design-vue";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      isPassingLock: false,
      userName: "",
      pwd: "",
      submitLoading: false,
      form: this.$form.createForm(this, { name: "login" }),
      password: "",
      tipsInfo: "",
      showPwdVis: false,
      loginErrorTimerf: null,
      Loading: false,
      /**默认密文 密码显示 */
      showpass: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  components: {
    "a-spin": spin,
  },
  mounted() {
    this.userName = this.userInfo.user.userName;
  },
  methods: {
    handleSubmit(e) {
      if (this.loginErrorTimerf) {
        clearTimeout(this.loginErrorTimerf);
      }
      this.loginErrorTimerf = setTimeout(() => {
        if (this.Loading) {
          return;
        }
        this.getLogin();
      }, 500);

      // e.preventDefault();
      // this.form.validateFields(async (err, values) => {

      // });
    },
    async getLogin() {
      // this.Loading = true;
      if (!this.userName || !this.password) {
        this.submitLoading = true;
        this.tipsInfo = "账号密码不能为空";
        return;
      }
      this.Loading = true;
      let params = {
        username: this.userName,
        password: this.password,
      };
      try {
        let res = await loginApi.login(params);
        this.Loading = false;
        let { token } = res;
        this.$store.commit(MutationType.SET_TOKEN_MUTATION, token);
        storage.setToken(token);
        this.$store.commit("setSuerParams", JSON.stringify(params));
        // storage.setSerParams(params);
        // this.$success({
        //   title: res.msg,
        // });
        this.$router.push({
          path: "/datav",
        });
      } catch (error) {
        console.log(error);
        this.Loading = false;
        this.$message.error(error.msg);
      }
    },
    hideShowPsw() {
      this.showpass = !this.showpass;
      var demoImg = document.getElementById("demo_img");
      var demoInput = document.getElementById("demo_input");
      if (demoInput.type == "password") {
        demoInput.type = "text";
        demoImg.src = "invisible.png";
      } else {
        demoInput.type = "password";
        demoImg.src = "visible.png";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>

<style>
/* body {
  margin: 0;
  height: 100vh;
  width: 100%;
  color: black;
  background: #0c0c0b;
  overflow: hidden;
  display: grid;
  place-content: center;
} */
.login {
  background: #151617;
  width: 400px;
  padding: 30px;
  border-radius: 30px;
  display: grid;
  gap: 3px;
}

.logintext {
  font-family: "Inter";
  color: #6779f5;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
}

@property --anim {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.field {
  background: #6779f5;
  border-radius: 15px;
  position: relative;
  height: 50px;
  --anim: 0;
  --anim-adjusted-right: calc((var(--anim) - 0.75) / 0.25);
  --anim-adjusted-top: calc((var(--anim) - 0.65) / 0.1);

  transition: --anim 500ms ease;
  background: linear-gradient(
      to right,
      #6779f5 calc(clamp(0, var(--anim-adjusted-right), 1) * 33%),
      transparent calc(clamp(0, var(--anim-adjusted-right), 1) * 33%),
      transparent calc(100% - clamp(0, var(--anim-adjusted-right), 1) * 33%),
      #6779f5 calc(100% - clamp(0, var(--anim-adjusted-right), 1) * 33%)
    ),
    linear-gradient(
      to top,
      transparent calc(15% + clamp(0, var(--anim-adjusted-right), 1) * 70%),
      #202122 calc(15% + clamp(0, var(--anim-adjusted-right), 1) * 70%)
    ),
    linear-gradient(
      to right,
      transparent calc(50% - clamp(0, var(--anim) / 0.65, 1) * 50%),
      #6779f5 calc(50% - clamp(0, var(--anim) / 0.65, 1) * 50%),
      #6779f5 calc(50% + clamp(0, var(--anim) / 0.65, 1) * 50%),
      transparent calc(50% + clamp(0, var(--anim) / 0.65, 1) * 50%)
    ),
    linear-gradient(#202122, #202122);
  margin-bottom: 10px;
}

.field:has(input:focus) {
  --anim: 1;
}

.field > .placeholder {
  pointer-events: none;
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center;
  color: #7d8dff;
  /* font-family: "Inter"; */
  transition: transform 500ms ease;
  font-size: 18px;
  font-weight: 600;
}

.field:has(input:focus) > .placeholder,
.field:has(input:not(:placeholder-shown)) > .placeholder {
  transform: translateY(-50%) scale(0.85);
}

.field > input {
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: white;
  position: absolute;
  inset: 2.5px;
  border-radius: 12.5px;
  border: none;
  outline: none;
  background: #202122;
  height: 44px;
  width: calc(100% - 6px);
}

.loginbtn {
  background: radial-gradient(
    circle at center,
    #6779f5 calc(-50% + var(--anim) * 150%),
    #202122 calc(var(--anim) * 100%)
  );
  border-radius: 15px;
  position: relative;
  height: 50px;
  display: grid;
  place-content: center;
  color: #7d8dff;
  /* font-family: "Inter"; */
  --anim: 0;
  transition: --anim 500ms ease, color 500ms ease;
  font-size: 18px;
  font-weight: 600;
}

.loginbtn:hover {
  --anim: 1;
  color: white;
  cursor: pointer;
}
</style>
